
import {
  Vue,
  Component,
  Prop,
  PropSync,
} from 'vue-property-decorator';
import {
  FormMetadata,
  Property,
  Value,
} from '@/types/vjsf';
import Message from '@/components/mixins/Message.vue';

interface File {
  blob?: Blob,
  name?: string,
  type?: string,
}

@Component({
  name: 'PreviewField',
  data: () => ({
    blob: { } as File,
    base64: '',
  }),
})
export default class PreviewField extends Vue {
  @PropSync('value', { required: true })
  private file!: Value;

  @Prop({ required: true })
  private readonly property!: Property;

  @Prop({ required: true })
  private readonly serverLocale!: string;

  @Prop()
  private readonly metadata: FormMetadata|undefined;

  private loading = false;

  protected mounted(): void {
    if (typeof this.file === 'string') {
      this.downloadFile(this.file);
    }
  }

  private downloadFile(id: string) {
    this.loading = true;

    const variables = {
      id,
    };

    import('@/graphql/queries/download-file')
      .then(({ default: query }) => this.$apollo.query({
        query,
        variables,
      }))
      .then(async (response) => {
        if (response.data.file === null) {
          return null;
        }
        const { filename, content } = response.data.file;
        const type = 'image/png';
        const imageObject = await fetch(`data:${type};base64,${content}`);
        const blob = await imageObject.blob();

        this.$data.blob = { filename, type, blob };

        return blob;
      })
      .then(async (blob) => {
        if (blob === null) {
          return;
        }
        this.$data.base64 = `data:image/jpeg;base64,${btoa(
          new Uint8Array(await blob.arrayBuffer())
            .reduce((data, byte) => data + String.fromCharCode(byte), ''),
        )}`;
      })
      .catch((error) => {
        Message.error(this.$t('generic.error.occurred'));
        throw error;
      })
      .finally(() => {
        this.loading = false;
      });
  }
}

